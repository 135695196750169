import { C as Component, d as debounce } from "./Component.min.ac9d492e.js";
import { g as getVar } from "./cssVar.min.a83b954d.js";
import { e as emitter } from "./Emitter.min.eb356be5.js";
class Screen extends Component {
  onInit() {
    this.onEnter = debounce(this.onEnter.bind(this), 200, false);
    this.onLeave = debounce(this.onLeave.bind(this), 200, false);
    const screenVar = getVar({
      name: ["screen-sm", "screen-md", "screen-lg", "screen-xl"]
    });
    this.current = null;
    this.bp = Screen.setBp(screenVar);
    this.addListenerMQ();
  }
  addListenerMQ() {
    for (const bp of this.bp) {
      bp.test = window.matchMedia(bp.query);
      bp.test.addEventListener("change", this.onChange.bind(this, bp));
      if (bp.test.matches)
        this.onEnter(bp);
    }
  }
  onChange(bp) {
    if (bp.test.matches) {
      this.onEnter(bp);
    } else {
      this.onLeave(bp);
    }
  }
  onLeave(bp) {
    emitter.emit(`screen:leave:${bp.id}`, bp);
  }
  onEnter(bp) {
    const old = this.current;
    const current = bp;
    emitter.emit(`screen:enter:${bp.id}`, bp);
    if (old !== null && current.id === "xl" || (old == null ? void 0 : old.id) === "xl" && current.id !== "xl") {
      location.reload();
    }
    this.current = bp;
    emitter.emit(`screen:change`, { old, current });
  }
  static setBp(screen) {
    return [
      {
        id: "xs",
        query: `(max-width: ${screen["screen-sm"].value - 1}px)`
      },
      {
        id: "sm",
        query: `(min-width: ${screen["screen-sm"].value}px) and (max-width: ${screen["screen-md"].value - 1}px)`
      },
      {
        id: "md",
        query: `(min-width: ${screen["screen-md"].value}px) and (max-width: ${screen["screen-lg"].value - 1}px)`
      },
      {
        id: "lg",
        query: `(min-width: ${screen["screen-lg"].value}px) and (max-width: ${screen["screen-xl"].value - 1}px)`
      },
      {
        id: "xl",
        query: `(min-width: ${screen["screen-xl"].value}px)`
      }
    ];
  }
}
const Screen$1 = new Screen();
export {
  Screen$1 as S
};
